<template>
<div class="modal fade" id="linkModal" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="linkModal">Konfiguration speichern</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Sie bekommen einen Link zur aktuellen Konfiguration per E-Mail gesendet.</p>
              <hr>
              <div class="form-group">
                <label for="name">Ihr Name</label>
                  <input v-model="form.name" type="text" class="form-control" id="name" placeholder="Ihr Name">
              </div>
              <div class="form-group">
                  <label for="email">Ihre E-Mail</label>
                  <input v-model="form.email" type="text" class="form-control" id="email" placeholder="Ihre E-Mail">
              </div>
              <div v-if="msg" class="alert alert-info">{{ msg }}</div>
              <ul v-if="errors" class="list-unstyled">
                <li v-for="(val, key, index) in errors" :key="index" class="text-danger">{{ val }}</li>
              </ul>
            </div>
            <div class="modal-footer">
              <button @click="sendConfiguration" type="button" class="btn btn-primary">Speichern</button>
            </div>
        </div>
  </div>
</div>
</template>
<script>
import { ref } from '@vue/reactivity'
import axios from 'axios'
export default {
  setup () {
    const form = ref({
      name: '',
      email: ''
    })
    const msg = ref()
    const errors = ref([])
    async function sendConfiguration () {
      const confirm = window.confirm('Wir haben Ihnen eine Email mit dem Link zur aktuellen Konfiguration geschickt.')
      if (confirm) {
        const apiKey = btoa(process.env.VUE_APP_API_KEY)
        const apiUrl = process.env.VUE_APP_API_URL
        const url = window.location.href
        await axios.post(apiUrl + 'sendconfig', { re: apiKey, link: url, name: form.value.name, email: form.value.email }).then(resp => {
          errors.value = []
          if (resp.data.status) {
            form.value.name = ''
            form.value.email = ''
            msg.value = resp.data.msg
          } else {
            msg.value = ''
          }
        }).catch(error => {
          errors.value = Object.values(error.response.data.errors).flat()
        })
      }
    }
    return {
      form,
      msg,
      errors,
      sendConfiguration
    }
  }
}
</script>
